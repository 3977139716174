module.exports = {
  comm: {
    home: 'Trang chủ',
    pricing: 'Giá cả',
    blog: 'Sao chép giọng nói & API',
    language: 'ngôn ngữ:',
    select_language: 'chọn ngôn ngữ',
    txt2voice: 'chuyển văn bản thành giọng nói',
    voice2txt: 'chuyển giọng nói thành văn bản',
    voiceclone: 'sao chép giọng nói',
    video2txt: 'chuyển video thành văn bản',
    footer_help: 'Cần giúp đỡ? gửi thư cho chúng tôi:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Đăng nhập',
    login_desc: 'Đăng nhập để truy cập tài khoản TikTok Voice Generator của bạn',
    logout: 'Đăng xuất',
  },
  txt2voice: {
    title: 'TikTok Voice Generator: Tạo giọng nói AI TikTok hài hước',
    description: 'Tạo và tải xuống miễn phí các giọng nói AI TikTok hài hước như giọng jessie, giọng ghostface, giọng C3PO...',
    keywords: 'TikTok Voice generator,TikTok AI Voice,Tik Tok Voice Generator',
    main_title_p01: 'TikTok Voice Generator',
    main_title_p02: 'Tạo giọng nói TikTok hài hước: giọng jessie, giọng C3PO, giọng ghostface',
    main_textarea_holder: 'Nhập hoặc dán văn bản vào đây',
    main_genvoice: 'tạo',
    main_generating: 'đang tạo',
    main_input_empty: 'vui lòng nhập hoặc dán văn bản',
    daily_usage_limit_msg: '1) Đã đạt giới hạn sử dụng tối đa trong ngày. Vui lòng quay lại vào ngày mai. 2) Nếu cần gấp thêm hạn mức sử dụng, vui lòng gửi email cho chúng tôi.',
    text_max_prompt1: 'Vui lòng giới hạn số từ',
    text_max_prompt2: 'từ hoặc ít hơn!',
    popup_nologin_title: 'Nâng cấp gói đăng ký của bạn',
    popup_nologin_desc: 'Mở khóa thêm thời gian tạo giọng nói bằng cách nâng cấp lên gói đăng ký cao cấp.',
    popup_nologin_btntext: 'Khám phá các gói đăng ký',
    popup_nosub_title: 'Nâng cấp gói đăng ký của bạn',
    popup_nosub_desc: 'Mở khóa thêm thời gian tạo giọng nói bằng cách nâng cấp lên gói đăng ký cao cấp.',
    popup_nosub_btntext: 'Khám phá các gói đăng ký',
    popup_sublimit_title: 'Đăng ký đã hết hạn, vui lòng nâng cấp',
    popup_sublimit_desc: 'Nâng cao trải nghiệm của bạn với các tính năng cao cấp và truy cập không giới hạn.',
    popup_sublimit_btntext: 'Đi đến mua thêm',
  },
  pricing: {
    new_features_alert: "📣 Chúng tôi đã ra mắt dịch vụ 🎙️ Sao chép giọng nói và 🤖 API cho thành viên trả phí! (Sau khi thanh toán, hãy gửi email đến tiktokaivoicetool@gmail.com để được hỗ trợ về các tính năng này). Ngoài ra, đừng ngần ngại gửi email cho chúng tôi về bất kỳ yêu cầu tùy chỉnh nào😃",
    pricing_title: 'Từ người sáng tạo cá nhân đến doanh nghiệp lớn, có một gói phù hợp với bạn.',
    pricing_desc: 'Hơn 100 giọng nói tự nhiên, con người. Hơn 15 ngôn ngữ. Giá thấp nhất cho hầu hết mọi người',
    sub_free_name: 'Miễn phí',
    sub_free_desc: 'Dành cho cá nhân muốn thử âm thanh AI tiên tiến nhất',
    sub_free_content: [
      'Giới hạn 1000 ký tự mỗi tháng',
      '5 lần tạo mỗi ngày',
    ],
    sub_starter_name: 'Khởi đầu',
    sub_starter_desc: 'Dành cho người sáng tạo nội dung cao cấp cho khán giả toàn cầu',
    sub_starter_content: [
      '1 bản sao giọng nói',
      'Giới hạn 300.000 ký tự mỗi tháng (khoảng 7 giờ âm thanh)',
      'Tải xuống không giới hạn',
      'Hỗ trợ qua email trong vòng 72 giờ',
      'Ưu tiên truy cập giọng nói và tính năng mới',
    ],
    sub_pro_name: 'Chuyên nghiệp',
    sub_pro_desc: 'Dành cho người sáng tạo nội dung đang mở rộng sản xuất',
    sub_pro_content: [
      'Truy cập API',
      '3 bản sao giọng nói',
      'Giới hạn 1.000.000 ký tự mỗi tháng (khoảng 24 giờ âm thanh)',
      'Tải xuống không giới hạn',
      'Hỗ trợ qua email trong vòng 48 giờ',
      'Ưu tiên truy cập giọng nói và tính năng mới',
    ],
    period_year: 'Hàng năm',
    period_month: 'Hàng tháng',
    period_month_short: 'tháng',
    billed_year: 'Thanh toán hàng năm',
    most_popular: 'Phổ biến nhất',
    discount_quantity: 'Tiết kiệm $48',
    buy_btntext: 'Bắt đầu',
    pay_succ: 'Thanh toán thành công!',
    pay_succ_desc: 'Cảm ơn bạn đã mua hàng. Giao dịch của bạn đã được xử lý thành công.',
    pay_succ_btntext: 'Bắt đầu sử dụng TikTok Voice',
    pay_fail: 'Thanh toán thất bại!',
    pay_fail_desc: "Xin lỗi, chúng tôi không thể xử lý giao dịch của bạn. Vui lòng thử lại hoặc liên hệ với đội hỗ trợ của chúng tôi (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Quay lại trang chủ',
  },
  setting: {
    setting: 'Cài đặt',
    setting_title: 'Quản lý tài khoản, sử dụng và đăng ký của bạn tại đây.',
    basic_info: 'Thông tin cơ bản',
    user_name: 'Tên',
    user_email: 'Email',
    user_account: 'Tài khoản',
    user_subscript: 'Gói hiện tại',
    user_usage: 'Sử dụng',
    manage_subscript: 'Quản lý đăng ký',
    use_limit_promote: "Bạn đã sử dụng 5000 trong giới hạn 10000 ký tự hàng tháng của mình.",
    not_logged_in: 'Bạn chưa đăng nhập',
    go_to_login: 'Đi đến đăng nhập >>',
  },
  login: {
    title: "TikTok Voice Generator",
    subtitle: "Đăng nhập để truy cập tài khoản TikTok Voice Generator của bạn",
    alreadyLoggedIn: "Bạn đã đăng nhập",
    goToHome: "Đi đến trang chủ"
  },
  faq: {
    quest1: 'TikTok Voice Generator - Công cụ giọng nói AI TikTok tốt nhất',
    answer1_1: 'Đây là một công cụ tạo giọng nói AI tiktok được phát triển bởi công nghệ tts tiktok mới nhất, có thể tạo ra giọng nói tiktok như giọng jessie (giọng nữ), giọng siri, giọng ghostface, giọng C3PO (giọng robot), giọng sâu (người kể chuyện), giọng skye, giọng ấm áp, giọng bestie, giọng anh hùng (chirisvoice), giọng đồng cảm, giọng nghiêm túc, giọng joey, giọng stitch, giọng Stormtrooper (Star Wars), giọng Rocket (Guardians of the Galaxy).',
    answer1_2: 'Các giọng nói AI tiktok sẽ được hỗ trợ sớm bao gồm: giọng derek, giọng trickster, giọng austin butler, giọng phát thanh viên, giọng adam, giọng chó, giọng miley cyrus, giọng alexa, giọng người ngoài hành tinh, giọng động vật, giọng em bé, giọng máy tính, giọng sóc, giọng vang vọng, giọng npr.',
    answer1_3: 'Ngoài ra, công cụ tạo giọng nói tiktok còn hỗ trợ nhiều ngôn ngữ khác, bao gồm tiếng Trung, tiếng Nhật, tiếng Hàn, tiếng Việt, tiếng Thái, tiếng Hindi, tiếng Farsi, tiếng Nga, tiếng Đức, tiếng Pháp, tiếng Romania, tiếng Séc, tiếng Tây Ban Nha, tiếng Bồ Đào Nha, tiếng Bengal, tiếng Ý, tiếng Ả Rập, tiếng Urdu, tiếng Trung Quốc truyền thống và tiếng Mã Lai.',
    answer1_4: 'Nếu bạn cần gấp một giọng nói cụ thể, vui lòng gửi email cho tôi.',
    
quest2: 'Ưu điểm của TikTok Voice Generator là gì?',
    answer2_1: '- Công cụ TikTok Voice Generator có thể tạo ra nhiều loại giọng nói khác nhau, thường được sử dụng trong các video TikTok.',
    answer2_2: '- Đây là công cụ tạo giọng nói AI mới nhất có thể tạo ra giọng tts tiktok giống người.',
    answer2_3: '- Nó thuận tiện hơn cho việc chỉnh sửa video trên PC.',
    answer2_4: '- Bạn có thể sử dụng một số giọng nói mà hiện không thể tìm thấy trong TikTok APP tts.',
    
quest3: 'Cách sử dụng TikTok Voice Generator?',
    answer3_1: 'TikTok Voice Generator rất dễ sử dụng:',
    answer3_2: '- Chọn ngôn ngữ và giọng nói.',
    answer3_3: '- Nhập văn bản cần chuyển thành giọng nói vào ô nhập.',
    answer3_4: '- Nhấn nút tạo và chờ vài giây.',
    answer3_5: '- Phát hoặc tải xuống giọng nói AI tiktok.',
    
quest4: 'Các giọng nói nổi tiếng và hài hước nhất trên tiktok là gì?',
    answer4_1: 'Giọng nói nam nổi tiếng nhất trên tiktok là giọng sâu, được gọi là người kể chuyện.',
    answer4_2: 'Đối vớigiọng nói tiktok hài hước nhất, tôi cá nhân khuyên dùng giọng ghostface và giọng C3PO. Chúng thường được sử dụng để lồng tiếng cho các video TikTok hài hước vì chúng dễ nhận biết và có thể nhanh chóng thu hút người xem.',
    
quest5: 'Giọng nói TikTok gây khó chịu nhất là gì?',
    answer5_1: 'Tôi tin rằng không có giọng tiktok thực sự "khó chịu".',
    answer5_2: 'Đó là về ngữ cảnh và nội dung sử dụng giọng nói có thể làm một số người cảm thấy khó chịu. Ví dụ, một số người có thể thấy giọng jessie khó chịu vì nó xuất hiện quá thường xuyên trên TikTok trong một thời gian nhất định. Tuy nhiên, với âm sắc và chất lượng của nó, thực sự đó là một hiệu ứng giọng nói tiktok tuyệt vời. Ngoài ra, diễn viên lồng tiếng tiktok đứng sau nó rất chuyên nghiệp và cô ấy cũng đăng video của mình trên TikTok.',
    answer5_3: 'Vì vậy, tôi đề nghị mọi người bỏ qua những lo ngại và định kiến, nghe giọng nói từ công cụ này nhiều lần và kiên nhẫn tìm kiếm hiệu ứng giọng nói tiktok phù hợp nhất với bạn.',
    
quest6: 'Cách sử dụng TikTok Voice Generator để tạo giọng jessie?',
    answer6_1: '- Trong menu thả xuống đầu tiên của công cụ TikTok Voice Generator, chọn English (US), sau đó trong menu thả xuống khác, chọn giọng Jessie (giọng nữ).',
    answer6_2: '- Nhập văn bản vào ô nhập và nhấn nút tạo.',
    answer6_3: '- Chờ vài giây hoặc tối đa mười giây, bạn sẽ nghe thấy giọng AI. Trên thanh công cụ, tìm nút có mũi tên hướng xuống và nhấn để tải xuống.',
    
quest7: 'Cách sử dụng TikTok Voice Generator để tạo giọng C3PO?',
    answer7_1: '- Trong menu thả xuống đầu tiên của công cụ TikTok Voice Generator, chọn English (US), sau đó trong menu thả xuống khác, chọn giọng C3PO (giọng robot).',
    answer7_2: '- Nhập văn bản vào ô nhập và nhấn nút tạo.',
    answer7_3: '- Chờ vài giây hoặc tối đa mười giây, bạn sẽ nghe thấy giọng AI. Trên thanh công cụ, tìm nút có mũi tên hướng xuống và nhấn để tải xuống.',
    
quest8: 'Cách sử dụng TikTok Voice Generator để tạo giọng ghostface?',
    answer8_1: '- Trong menu thả xuống đầu tiên của công cụ TikTok Voice Generator, chọn English (US), sau đó trong menu thả xuống khác, chọn giọng ghostface.',
    answer8_2: '- Nhập văn bản vào ô nhập và nhấn nút tạo.',
    answer8_3: '- Chờ vài giây hoặc tối đa mười giây, bạn sẽ nghe thấy giọng AI. Trên thanh công cụ, tìm nút có mũi tên hướng xuống và nhấn để tải xuống.',
    
quest9: 'Cách sử dụng TikTok Voice Generator để tạo giọng siri?',
    answer9_1: '- Trong menu thả xuống đầu tiên của công cụ TikTok Voice Generator, chọn English (US), sau đó trong menu thả xuống khác, chọn giọng siri.',
    answer9_2: '- Nhập văn bản vào ô nhập và nhấn nút tạo.',
    answer9_3: '- Chờ vài giây hoặc tối đa mười giây, bạn sẽ nghe thấy giọng AI. Trên thanh công cụ, tìm nút có mũi tên hướng xuống và nhấn để tải xuống.',
    
quest10: 'Cách thêm hiệu ứng giọng nói tiktok vào video TikTok?',
    answer10_1: 'Nếu bạn muốn biết cách sử dụng các giọng nói chính thức của tiktok trên TikTok, tôi sẽ sớm viết một bài đăng blog chuyên về chủ đề này.',
    answer10_2: 'Ở đây, tôi sẽ giải thích cách đăng giọng nói của bạn lên TikTok sau khi tạo và tải xuống từ TikTok Voice Generator.',
    answer10_3: '1. Nếu bạn đã tạo giọng nói tiktok trên PC và muốn tải lên TikTok hoặc bất kỳ ứng dụng chỉnh sửa video nào khác trên điện thoại của bạn, bạn cần chuyển tệp giọng nói sang điện thoại của bạn. Đối với iPhone, bạn có thể sử dụng airDrop để chuyển. Đối với điện thoại Android, tôi không quen với quy trình này, nhưng bạn chắc chắn sẽ tìm thấy phương pháp và công cụ để làm điều đó.',
    answer10_4: '2. Khi tệp giọng nói đã được chuyển sang điện thoại của bạn, bạn có thể mở TikTok:',
    answer10_5: '- Nhấn vào nút "+" ở cuối giao diện, chọn video trong điện thoại của bạn.',
    answer10_6: '- Sau khi tải lên video, tìm biểu tượng hình vuông ở phía bên phải màn hình để vào trang chỉnh sửa.',
    answer10_7: '- Ở cuối trang, tìm nút "thêm giọng nói" và nhấn để chọn tệp giọng nói mà bạn vừa chuyển.',
    answer10_8: '- Trong giao diện này, bạn có thể thực hiện một số điều chỉnh cho video, sau đó nhấn nút ở góc trên bên phải để đăng bài.',
    
quest11: 'TikTok Voice có miễn phí không?',
    answer11: 'Có, đây là một công cụ chuyển văn bản thành giọng nói AI tiktok miễn phí.',
    
quest12: 'Tôi có thể tạo giọng nói TikTok phổ biến vào video TikTok của mình ở đâu?',
    answer12: 'Bạn có thể truy cập https://tiktokvoice.net/ để thêm giọng nói AI tiktok vào video của mình.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}